@use "../abstracts" as a;

.form {
    &-control {
        // font-size: a.size(text);
        border-radius: 6px;
        // @include a.placeholder(#e1e1e1, 12px, 15px);
        border: unset;
    }
}

.input_invalid {
    // border: 1px solid a.color(error);
}
