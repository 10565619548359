@use "../abstracts" as *;
@import "../../variables";

.title {
    margin-bottom: unset;

    &::after {
        content: "";
        display: block;
        width: 66px;
        height: 3px;

        background-color: $blue;
    }
}

.placeholder {
    background-color: color(placeholder);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.map {
    .embed-responsive {
        max-height: 283px;
    }
}
