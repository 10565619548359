@use "./base";

// Components
@use "./components/header";
@use "./components/nav";
@use "./components/footer";
@use "./components/social-medias";
@use "./components/whats";
@use "./components/floating-form";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
