@use "../abstracts" as *;
@import "../../variables";

.header {
    background-color: $white;
    z-index: 1000;
    position: relative;

    @include for-desktop-up {
        position: sticky;
        top: 0px;
        left: 0px;

    }

    &-section {
        padding: 17px 0;
        position: sticky;
        box-shadow: 0px 4px 8px 3px #00000026;

    }

    &-top {
        background-color: #004C8B;
        height: 49px;

        .top-contact {
            &__link {
                color: $white;
            }

        }

        .social-top {
            &__item {
                color: $white;
                font-size: 20px;

                a:hover {
                    color: $white;
                }
            }
        }

    }

}
