@use "../abstracts" as *;
@import "../../variables";

.footer {

    &__images {

        img {
            height: 80px;
        }
    }
}

.information {
    background-color: #ED1B24;
    box-shadow: 0px 3px 4px 0px #00000026;
    @include for-phone-only{
        padding: 10px 10px 10px 10px !important;
    }
    &__title {
        font-family: Roboto;
        font-size: 19px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;

        @include for-phone-only{
            margin-bottom: 4px;
        }

    }

    &__item {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;

        &:not(:last-of-type) {
            margin-bottom: 0.5rem;
        }

        @include for-phone-only{
            margin-bottom: 2px !important;
        }
    }

    &__link {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;

        &:hover {
            color: #acacac;
        }
    }

    &__icon {
        color: #FFFFFF;
        font-size: 16px;
        margin-right: 0.5rem;
    }

    &__column {
        margin-bottom: 1rem;
        width: 50%;

        @include for-desktop-up {
            margin-bottom: unset;
            width: 100%;
        }
    }

    @media (max-width: 1200px) {
        background-image: unset !important;
    }
}

.rights {
    align-items: center;
    min-height: 40px;
    padding: 0.5rem 0px;
    background-color: #F5F5F5;

    p {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #2E2E2E;

        @include for-phone-only {
            text-align: center;
        }
    }

    @include for-desktop-up {
        flex-direction: row;
    }

    @include for-phone-only {
        flex-direction: column;
        text-align: center;
    }
}
